import React, { PureComponent, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { BrandVacationsCalendar } from '../CoreComponents/Calendar';
import { BoldText } from '../CoreComponents/Typography';
import { Hook, useHook, useQuery, useStoreContext } from "../CoreComponents/Utils";
import { AdminService, NationalHoliday } from '../Services/admin.service';
import { Department, DepartmentService } from '../Services/department.service';
import { GenderType, StatusType, User, UserService } from "../Services/user.service";
import { LeaveWallVacations, LeaveRequest, VacationService } from '../Services/vacation.service';
import { BrandButton } from '../CoreComponents/Button';
import { addDays, addMonths, endOfMonth, format, startOfMonth, subDays, subMonths } from 'date-fns';
import { SelectedLeaveRequest, VacationRequest } from './UserLeaveWall';
import { useHistory } from 'react-router-dom';
import { Input } from '../CoreComponents/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TagEntry, TagService } from '../Services/tag.service';
import { BrandModal, WarningConfirmationDialog } from '../CoreComponents/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import { BrandTagIcon } from '../CoreComponents/CustomIcons';
import Chip from '@material-ui/core/Chip';
import { PageDataLoading } from '../CoreComponents/PageDataLoading';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export interface ApproverLeaveWallProps {
    $userData: Hook<User>;
    $navigationComponent: Hook<any>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: 'auto',
    },

    userRow: {
        display: 'inline-flex',
        marginBottom: theme.spacing(5),
        '&>*': {
            margin: 'auto 0',
        },
    },
    daysUser: {
        width: theme.spacing(8),
        minWidth: theme.spacing(8),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    daysRemaining: {
        width: theme.spacing(10),
        minWidth: theme.spacing(10),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: '#ff6073',
    },
    nationalHolidays: {
        width: theme.spacing(10),
        minWidth: theme.spacing(10),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: '#00b828',
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.spacing(30),
        minWidth: theme.spacing(30),
        padding: theme.spacing(0, 0, 1, 0),
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.grey[300],
        },
    },

    userCardInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
    },
    cardTitle: {
        fontWeight: 'bold',
        color: '#727272',
        textDecoration: 'unset',
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        margin: theme.spacing(1, 3, 'auto', 1),
        fontWeight: 'bold',
    },
    avatarFemale: {
        backgroundColor: '#FFB2BB',
        color: '#FF6073',
    },
    avatarMale: {
        backgroundColor: '#E6F5FF',
        color: '#18A0FB',
    },
    avatarInactive: {
        backgroundColor: '#EFEFEF',
        color: '#727272',
    },

    controlsBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(0, 0, 4, 0),
    },
    calendarControls: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: 'auto',
        marginLeft: 'auto',
        '& > *': {
            margin: theme.spacing('auto', 1.2),
        },
    },
    calendarBarDate: {
        fontWeight: 'bold',
        fontSize: '1.5em',
    },
    subordinatesList: {
        display: 'flex',
        flexDirection: 'column',
    },

    selectedTag: {
        margin: 'auto 0',
    },
    tagsEditButtonIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: '#ffa3ae',
    },
    requestLeaveButtonIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: '#18a0fb',
    },
}));

// The item renderer is declared outside of the list-rendering component.
// So it has no way to directly access the items array.

class SubordinateEntryRenderer extends PureComponent {
    render() {
        // @ts-ignore
        const style = this.props.style;
        // @ts-ignore
        const data = this.props.data[this.props.index];
        const user = data.data;
        const classes = data.classes;
        const history = data.history;
        const store = data.store;
        const $subordinateUsersLeaves = data.$subordinateUsersLeaves;
        const $date = data.$date;
        const $nationalHolidays = data.$nationalHolidays;
        const onClickedCalendar = data.onClickedCalendar;
        const q = data.q;

        return (
            <div style={style} className={classes.userRow}>
                <div className={classes.daysUser}>
                    <BoldText>{user.daysUsed}</BoldText>
                </div>
                <div className={classes.daysRemaining}>
                    <BoldText>{user.daysRemaining}</BoldText>
                </div>
                <div className={classes.nationalHolidays}>
                    <BoldText>{user.nationalHolidays}</BoldText>
                </div>
                <div
                    className={classes.userInfo}
                    onClick={() => {
                        store.$selectedUser.set(user);
                        history.push(`/wall/${user.id}?q=${q}`);
                    }}
                >
                    <Avatar
                        className={clsx(
                            classes.avatar,
                            user.status === StatusType.Inactive ? classes.avatarInactive
                                : user.gender === GenderType.Female ? classes.avatarFemale
                                    : classes.avatarMale
                        )}
                    >
                        {user.firstName[0] + user.lastName[0]}
                    </Avatar>
                    <div className={classes.userCardInfo}>
                        <span className={classes.cardTitle}>
                            {user.firstName} {user.lastName}
                        </span>
                        <span>{user.jobPosition}</span>
                        <span>ID-{user.id}</span>
                    </div>
                </div>
                <BrandVacationsCalendar
                    isRow={true}
                    // onlyCurrentMonth
                    requestedLeaves={$subordinateUsersLeaves.value ? $subordinateUsersLeaves.value[user.id] : undefined}
                    nationalHolidays={$nationalHolidays.value}
                    date={$date.value}
                    onChange={(date, requestedLeave, holiday) => onClickedCalendar(user, date, requestedLeave, holiday)}
                // classes={{ root: classes.calendar }}
                />
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubordinateEntry = (
    { q, user, departments, nationalHolidays, $date, requestedLeaves, onDeletedRequest }
        : { q: string, user: User, departments: Department[], nationalHolidays: NationalHoliday[], $date: Hook<Date>, requestedLeaves?: LeaveRequest[], onDeletedRequest: (request: LeaveRequest) => void }
) => {
    const history = useHistory();
    const store = useStoreContext();
    const classes = useStyles();
    const $department = useHook('');
    const $selectedRequestedLeave = useHook<LeaveRequest>({} as any);
    const $isSelectedRequestedLeave = useHook<boolean>(false);

    useEffect(() => {
        $department.set(
            departments.find(x => x.id === user.departmentId)?.name
            || 'unable to resolve department'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);

    const onClickedCalendar = (date, requestedLeave, holiday) => {
        if (!requestedLeave) {
            return;
        }

        $selectedRequestedLeave.set(requestedLeave);
        $isSelectedRequestedLeave.set(true);
    }

    return <div className={classes.userRow}>
        <div className={classes.daysUser}>
            <BoldText>{user.daysUsed}</BoldText>
        </div>
        <div className={classes.daysRemaining}>
            <BoldText>{user.daysRemaining}</BoldText>
        </div>
        <div className={classes.nationalHolidays}>
            <BoldText>{user.nationalHolidays}</BoldText>
        </div>
        <div
            className={classes.userInfo}
            onClick={() => {
                store.$selectedUser.set(user);
                history.push(`/wall/${user.id}?q=${q}`);
            }}
        >
            <Avatar
                className={clsx(
                    classes.avatar,
                    user.status === StatusType.Inactive ? classes.avatarInactive
                        : user.gender === GenderType.Female ? classes.avatarFemale
                            : classes.avatarMale
                )}
            >
                {user.firstName[0] + user.lastName[0]}
            </Avatar>
            <div className={classes.userCardInfo}>
                <span className={classes.cardTitle}>
                    {user.firstName} {user.lastName}
                </span>
                <span>{user.jobPosition}</span>
                <span>ID-{user.id}</span>
            </div>
        </div>
        <BrandVacationsCalendar
            isRow={true}
            // onlyCurrentMonth
            requestedLeaves={requestedLeaves}
            nationalHolidays={nationalHolidays}
            date={$date.value}
            onChange={onClickedCalendar}
        // classes={{ root: classes.calendar }}
        />
        {$isSelectedRequestedLeave.value ?
            <SelectedLeaveRequest
                user={user}
                $isOpen={$isSelectedRequestedLeave}
                selectedLeave={$selectedRequestedLeave.value}
                onDeletedRequest={onDeletedRequest}
            />
            : null}
    </div>
}

const useEditTagsStyles = makeStyles((theme) => ({
    paper: {
        // width: '90vw',
        // maxWidth: theme.spacing(100),
        maxWidth: 'unset',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    column: {
        margin: theme.spacing(1),
        minWidth: theme.spacing(45),
        '&:first-child:not(:last-child)': {
            borderRight: '0.2px solid rgba(113, 113, 113, 0.49)',
            paddingRight: theme.spacing(1),
        },
    },
    inputField: {
        minWidth: theme.spacing(35),
        marginRight: theme.spacing(1),
    },
    addForm: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: theme.spacing(30),
        marginBottom: theme.spacing(1),
    },
    doneButtonContainer: {
        display: 'flex',
    },
    doneButton: {
        margin: 'auto 0 auto auto',
    },

    tagsList: {
        overflowY: 'auto',
        maxHeight: '40vh',
        display: 'flex',
        flexDirection: 'column',
    },
    chipItem: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 0),
        '& > .MuiChip-label': {
            marginRight: 'auto',
        },
    },
}));
export const EditTags = ({ $isOpen, $tags, subordinates }: { $isOpen: Hook<boolean>, $tags: Hook<TagEntry[]>, subordinates: User[] }) => {
    const classes = useEditTagsStyles();
    const $selectedTag = useHook<TagEntry | null>(null);
    const $newTagName = useHook('');
    const $selectedUser = useHook<User | null>(null);
    const $filteredUsers = useHook<User[]>(subordinates);
    const $selectedUserToDelete = useHook<User | null>(null);
    const $selectedTagToDelete = useHook<TagEntry | null>(null);
    const $waitingToDelete = useHook(false);

    const updateSelectedTag = (tagChanges: TagEntry) => {
        $selectedTag.set(tagChanges);
        const updatedTags = [
            tagChanges,
            ...$tags.value.filter(x => x.id !== tagChanges.id)
        ];
        $tags.set(updatedTags);
    };

    useEffect(() => {
        $newTagName.set('');
        $selectedUser.set(null);
        $filteredUsers.set(
            subordinates.filter(x => !$selectedTag.value?.users.includes(x.id))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$selectedTag.value]);

    return <BrandModal
        title={`Tag Settings`}
        open={$isOpen.value}
        onClose={() => $isOpen.set(false)}
        onCloseButton={() => $isOpen.set(false)}
        classes={{ paper: classes.paper }}
    >
        <div className={classes.column}>
            <div className={classes.root}>
                <div className={classes.column}>
                    <span>Create Tag</span>
                    <div className={classes.addForm}>
                        <Input classes={{ root: classes.inputField }} size="small" $value={$newTagName} />
                        <BrandButton
                            color="primary"
                            onClick={() => {
                                if (!$newTagName.value) {
                                    return;
                                }

                                TagService.addTag({ name: $newTagName.value, users: [] })
                                    .then(data => {
                                        $newTagName.set('');
                                        $tags.set([
                                            data,
                                            ...$tags.value
                                        ]);
                                        $selectedTag.set(data);
                                    });
                            }}
                        >
                            Add
                        </BrandButton>
                    </div>
                    <span>My Tags</span>
                    <div className={classes.tagsList}>
                        {$tags.value.map(tag =>
                            <Chip
                                key={tag.id}
                                onDelete={() => $selectedTagToDelete.set(tag)}
                                classes={{ root: classes.chipItem }}
                                color={$selectedTag.value === tag ? "primary" : undefined}
                                label={tag.name}
                                avatar={<Avatar>{tag.users.length}</Avatar>}
                                onClick={() => $selectedTag.set(tag)}
                            />
                        )}
                    </div>
                </div>
                {$selectedTagToDelete.value ?
                    <WarningConfirmationDialog
                        isLoading={$waitingToDelete.value}
                        isOpen={$selectedTagToDelete.value}
                        message={`Are you sure you want to remove tag '${$selectedTagToDelete.value.name}'?`}
                        onCancel={() => $selectedTagToDelete.set(null)}
                        onOk={() => {
                            $waitingToDelete.set(true);
                            TagService.deleteTag($selectedTagToDelete.value!.id as number)
                                .then(() => {
                                    $selectedTagToDelete.set(null);
                                    $waitingToDelete.set(false);

                                    if ($selectedTag.value === $selectedTagToDelete.value) {
                                        $selectedTag.set(null);
                                    }

                                    $tags.set($tags.value.filter(x => x.id !== $selectedTagToDelete.value!.id));
                                },
                                    err => $waitingToDelete.set(false));
                        }}
                    />
                    : null}
                <div className={classes.column}>
                    {$selectedTag.value ? <>
                        <div>Members in tag: <BoldText>{$selectedTag.value.name}</BoldText></div>
                        <div>
                            <div className={classes.addForm}>
                                <Autocomplete
                                    classes={{ root: classes.inputField }}
                                    size="small"
                                    options={$filteredUsers.value}
                                    getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                                    value={$selectedUser.value}
                                    // disabled={$isReadOnly.value}
                                    onChange={(_, user) => $selectedUser.set(user)}
                                    renderInput={(params) => <Input {...params} required label="Who for" variant="outlined" />}
                                />
                                <BrandButton
                                    color="primary"
                                    onClick={() => {
                                        if (!$selectedUser.value) {
                                            return;
                                        }

                                        const safeTag = ($selectedTag.value as TagEntry);
                                        const tagChanges: TagEntry = {
                                            ...safeTag,
                                            users: [
                                                ...safeTag.users,
                                                $selectedUser.value.id
                                            ]
                                        };
                                        TagService.editTag(tagChanges)
                                            .then(() => {
                                                updateSelectedTag(tagChanges);
                                                $selectedUser.set(null);
                                            });
                                    }}
                                >
                                    Add
                                </BrandButton>
                            </div>
                            <div className={classes.tagsList}>
                                {$selectedTag.value.users.map(uId => {
                                    const user = subordinates.find(x => x.id === uId) as User;
                                    return (user ?
                                        <Chip
                                            key={uId}
                                            onDelete={() => $selectedUserToDelete.set(user)}
                                            classes={{ root: classes.chipItem }}
                                            color="primary"
                                            label={`${user.firstName} ${user.lastName} ID-${user.id}`}
                                        />
                                        : null)
                                })}
                            </div>
                        </div>
                    </> : null}
                </div>
            </div>
            <div className={classes.doneButtonContainer}>
                <BrandButton
                    className={classes.doneButton}
                    color="primary"
                    onClick={() => $isOpen.set(false)}
                >
                    Done
                </BrandButton>
            </div>
            {$selectedUserToDelete.value ?
                <WarningConfirmationDialog
                    isLoading={$waitingToDelete.value}
                    isOpen={$selectedUserToDelete.value}
                    message={`Are you sure you want to remove ${$selectedUserToDelete.value.firstName} ${$selectedUserToDelete.value.lastName} from '${$selectedTag.value!.name}' tag?`}
                    onCancel={() => $selectedUserToDelete.set(null)}
                    onOk={() => {
                        $waitingToDelete.set(true);
                        const uId = $selectedUserToDelete.value!.id;

                        const safeTag = ($selectedTag.value as TagEntry);
                        const tagChanges: TagEntry = {
                            ...safeTag,
                            users: safeTag.users.filter(x => x !== uId)
                        };
                        TagService.editTag(tagChanges)
                            .then(() => {
                                $selectedUserToDelete.set(null);
                                $waitingToDelete.set(false);
                                updateSelectedTag(tagChanges);
                            },
                                err => $waitingToDelete.set(false));
                    }}
                />
                : null}
        </div>
    </BrandModal>
}

const useApproverSearchStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    backButton: {
        margin: theme.spacing('auto', 2, 'auto', 2),
        padding: theme.spacing(1),
    },
    searchButton: {
        margin: theme.spacing('auto', 2, 'auto', 0),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: theme.spacing(45),
        margin: theme.spacing('auto', 1),
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.4, 2),
        },
    },
}));
export const ApproverSearch = (
    { initialSearchValue, searchValueChange, ...props }
        : { initialSearchValue?: string | null, searchValueChange?: (string) => void, showSearchButton?: boolean, showBackButton?: boolean }
) => {
    const classes = useApproverSearchStyles();
    const history = useHistory();
    const { q } = useQuery();
    const $search = useHook<string>(q || initialSearchValue || '');

    useEffect(() => {
        if (!q) {
            return;
        }

        const value = q;
        $search.set(value);
        if (searchValueChange) {
            searchValueChange(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q]);

    return <form
        className={classes.root}
        onSubmit={e => {
            e.preventDefault();
            history.push(`/?q=${$search.value}`);
        }}
    >
        {props.showBackButton ?
            <IconButton
                onClick={() => history.goBack()}
                className={classes.backButton}
            >
                <ArrowBackIcon />
            </IconButton>
            : null}
        <Input
            placeholder="Search"
            value={$search.value}
            onChange={e => {
                const v = e.target.value.toLowerCase();
                $search.set(v);
                if (searchValueChange) {
                    searchValueChange(v);
                    history.replace({ search: `q=${v}` });
                }
            }}
            classes={{ root: classes.searchInput }}
        />
        {props.showSearchButton ?
            <BrandButton
                color="primary"
                type="submit"
                className={classes.searchButton}
            >
                <FontAwesomeIcon icon={faSearch} />
            </BrandButton>
            : null}
    </form>
}

export const ApproverLeaveWall = ({ $userData, $navigationComponent }: ApproverLeaveWallProps) => {
    const classes = useStyles();
    const { q } = useQuery();
    const { $filters } = useStoreContext();
    const $subordinateUsers = useHook<User[]>([]);
    const $filteredSubordinateUsers = useHook<User[]>([]);
    const $date = useHook(new Date());
    const $subordinateUsersLeaves = useHook<LeaveWallVacations | undefined>(undefined);
    const $departments = useHook<Department[]>([]);
    const $nationalHolidays = useHook<NationalHoliday[]>([]);
    const $showEditTagsModal = useHook(false);
    const $showVacationRequestModal = useHook(false);
    const $filter = useHook($filters.value.pageId === 'ApproverLeaveWall' ? $filters.value.data.filter : (q || ''));
    const $tags = useHook<TagEntry[]>([]);
    const $selectedTagId = useHook<number>($filters.value.pageId === 'ApproverLeaveWall' ? $filters.value.data.selectedTagId : 0);
    const $loading = useHook<boolean>(false);

    useEffect(() => {
        $loading.set(true);
        Promise.all([
            UserService.getAllSubordinates($userData.value.id)
                .then(data => $subordinateUsers.set(data)),
            DepartmentService.getAll()
                .then(data => $departments.set(data)),
            AdminService.nationalHolidays()
                .then(data => $nationalHolidays.set(data)),
            TagService.getAll()
                .then(data => $tags.set(data))
        ]).then(() => $loading.set(false))

        setTimeout(() => {
            $navigationComponent.set(
                <ApproverSearch initialSearchValue={$filter.value} searchValueChange={$filter.set} />
            )
        }, 50);

        return () => {
            $navigationComponent.set(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        $filters.set({
            pageId: 'ApproverLeaveWall',
            data: {
                filter: $filter.value,
                selectedTagId: $selectedTagId.value,
            },
        });
        let selectedTagUsers: number[] | any = [];
        if (!$tags.value.length) {
            selectedTagUsers = $tags.value.find(x => x.id === $selectedTagId.value)?.users;
            if (!selectedTagUsers) {
                $selectedTagId.set(0);
            }
        }

        $filteredSubordinateUsers.set(
            $subordinateUsers.value
                .filter(x => {
                    return (!selectedTagUsers || selectedTagUsers.includes(x.id))
                        && (x.firstName + ' ' + x.lastName + ' ID-' + x.id)
                            .toLowerCase()
                            .includes($filter.value)
                }

                )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$subordinateUsers.value, $filter.value, $selectedTagId.value, $tags.value]);

    useEffect(() => {
        const startDate = subDays(startOfMonth($date.value), 1);
        const endDate = addDays(endOfMonth($date.value), 1);
        $loading.set(true);
        VacationService.getSubordinatesLeaves($userData.value.id, startDate, endDate)
            .then(data => {
                $subordinateUsersLeaves.set(data);
                $loading.set(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$date.value]);

    const syncUser = userId => {
        const index = $subordinateUsers.value.findIndex(x => x.id === userId);
        if (index >= 0) {

            // WARN: may need loading indicator
            UserService.getById(userId)
                .then(data => {
                    $subordinateUsers.set([
                        ...$subordinateUsers.value.slice(0, index),
                        data,
                        ...$subordinateUsers.value.slice(index + 1)
                    ]);
                });
        }
    }

    const history = useHistory();
    const store = useStoreContext();

    const $selectedRequestedLeave = useHook<LeaveRequest>({} as any);
    const $isSelectedRequestedLeave = useHook<boolean>(false);
    const $user = useHook<User>({} as any);

    return <div className={classes.root}>
        <div className={classes.controlsBar}>
            <div className={classes.daysUser}>
                <BoldText>Days Used</BoldText>
            </div>
            <div className={classes.daysRemaining}>
                <BoldText>Days Remaining</BoldText>
            </div>
            <div className={classes.nationalHolidays}>
                <BoldText>National Holidays</BoldText>
            </div>

            <div className={classes.calendarControls}>
                <IconButton onClick={() => $date.set(subMonths($date.value, 1))}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
                <IconButton onClick={() => $date.set(addMonths($date.value, 1))}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
                <span className={classes.calendarBarDate}>
                    {format($date.value, 'MMMM y')}
                </span>
                <BrandButton
                    onClick={() => $date.set(new Date())}
                    variant="outlined"
                    color="primary"
                >
                    Today - {format(new Date(), 'd MMMM')}
                </BrandButton>
            </div>


            <BrandSelect
                $value={$selectedTagId}
                className={classes.selectedTag}
                size="small"
            >
                <BrandMenuItem value={0}>All users</BrandMenuItem>
                {$tags.value.map(tag =>
                    <BrandMenuItem value={tag.id} key={tag.id}>{tag.name}</BrandMenuItem>
                )}
            </BrandSelect>
            <IconButton onClick={() => $showEditTagsModal.set(true)}>
                <BrandTagIcon className={classes.tagsEditButtonIcon} />
            </IconButton>
            {$showEditTagsModal.value ?
                <EditTags
                    $isOpen={$showEditTagsModal}
                    $tags={$tags}
                    subordinates={$subordinateUsers.value}
                />
                : null}

            <IconButton onClick={() => $showVacationRequestModal.set(true)}>
                <AddCircleIcon className={classes.requestLeaveButtonIcon} />
            </IconButton>
            {$showVacationRequestModal.value ?
                <VacationRequest
                    $userData={$userData}
                    $isOpen={$showVacationRequestModal}
                    forUserId={undefined}
                    nationalHolidays={$nationalHolidays.value}
                    onAddedRequests={newRequests => {
                        const userId = newRequests[0].userId;
                        const userReq = $subordinateUsersLeaves.value ? $subordinateUsersLeaves.value[userId] : undefined;
                        $subordinateUsersLeaves.set({
                            ...$subordinateUsersLeaves.value,
                            [userId]: userReq ? newRequests.concat(userReq) : newRequests,
                        });
                        syncUser(userId);
                    }}
                    subordinates={$subordinateUsers.value}
                />
                : null}
        </div>

        {$loading.value ?
            <PageDataLoading />
            :
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        itemSize={() => 120}
                        itemCount={$filteredSubordinateUsers.value.length}
                        height={window.screen.height / 1.5}
                        width={width}
                        itemData={$filteredSubordinateUsers.value.map(x => {
                            return {
                                data: x,
                                classes: classes,
                                history: history,
                                store: store,
                                $subordinateUsersLeaves: $subordinateUsersLeaves,
                                $date: $date,
                                q: $filter.value,
                                $nationalHolidays: $nationalHolidays,
                                onClickedCalendar: (user, date, requestedLeave, holiday) => {
                                    if (!requestedLeave) {
                                        return;
                                    }

                                    $selectedRequestedLeave.set(requestedLeave);
                                    $isSelectedRequestedLeave.set(true);
                                    $user.set(user);
                                }
                            }
                        })}
                        className={classes.subordinatesList}
                    >
                        {SubordinateEntryRenderer}
                    </List>
                )}
            </AutoSizer>
        }

        {$isSelectedRequestedLeave.value ?
            <SelectedLeaveRequest
                user={$user.value}
                $isOpen={$isSelectedRequestedLeave}
                selectedLeave={$selectedRequestedLeave.value}
                onDeletedRequest={request => {
                    const userId = request.userId;
                    // @ts-ignore
                    const userReq = $subordinateUsersLeaves.value[userId] as LeaveRequest[];
                    $subordinateUsersLeaves.set({
                        ...$subordinateUsersLeaves.value,
                        [userId]: userReq.filter(x => x.id !== request.id),
                    });
                    syncUser(userId);
                }}
            />
            : null}
    </div>
}
